import "./assets/css/main.css";
import logo from "./images/logo.jpg";
function App() {

  return (
    <body className="is-preload">
      <div id="wrapper">
        <header id="header">
          <div className="inner">
            <span className="image fit">
              <img
                src={logo}
                title="Design by Hand Painted Stuff"
                alt="Design by Hand Painted Stuff"
              />
            </span>

            <a href="index.html" className="logo">
              <h1 className="title">Mrs Piano</h1>
            </a>
          </div>
        </header>

        <div id="main">
          <div className="inner">
            <section>
              <p>
                Mrs Rachael Cooper (Mrs Piano) - Piano Tuner for South Wales,
                Bristol area.
              </p>
              <p>
                Based in the Vale of Glamorgan, I offer piano tuning and repairs
                as well as French polishing at competitive prices.
              </p>

              <h2>Repairs</h2>
              <p>
                I can undertake repairs to your piano&apos;s action. Some
                repairs can be carried out on-site but for larger repairs, it
                may be necessary to remove the piano action and take it to the
                workshop. If any additional repairs or tuning requests are
                required, a price will be agreed upon before any work is carried
                out.
              </p>
              <p>
                It is recommended that pianos are tuned every 6 - 12 months.
              </p>

              <h2>Prices</h2>
              <ul class="alt">
                <li>Standard tune £100</li>
                <li>
                  Pitch raise and fine tune- extra £75 on top of standard tune.
                </li>
                <li>
                  There may be additional costs if any materials are required in
                  the tuning process, such as replacements for broken strings.
                </li>
              </ul>
              <h2>Contact me</h2>
              <ul className="alt">
                <li>
                  <span className="icon solid style1 fa-phone"></span>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <a href="tel: 07706059269">07706 059269</a>
                </li>
                <li>
                  <span className="icon solid style1 fa-envelope"></span>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <a href="mailto: rachael@mrs-piano.co.uk">
                    rachael@mrs-piano.co.uk
                  </a>
                </li>
              </ul>

              <p>Image design by Hand Painted Stuff.</p>
            </section>
          </div>
        </div>

        <footer id="footer">
          <div className="inner">
            {/* <section>
              <h2>Get in touch</h2>
              <form method="post" action="#">
                <div className="fields">
                  <div className="field half">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Name"
                    />
                  </div>
                  <div className="field half">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="field">
                    <textarea
                      name="message"
                      id="message"
                      placeholder="Message"
                    ></textarea>
                  </div>
                </div>
                <ul className="actions">
                  <li>
                    <input type="submit" value="Send" className="primary" />
                  </li>
                </ul>
              </form>
            </section> */}
            <section>
              <h2>Follow</h2>
              <ul className="icons">
                {/*  <li>
                  <a href="#" className="icon brands style2 fa-twitter">
                    <span className="label">Twitter</span>
                  </a>
                </li>
                <li>
                  <a href="#" className="icon brands style2 fa-facebook-f">
                    <span className="label">Facebook</span>
                  </a>
                </li> */}
                <li>
                  <a
                    href="https://www.instagram.com/_mrs_piano/"
                    className="icon brands style2 fa-instagram"
                  >
                    <span className="label">Instagram</span>
                  </a>
                </li>

                {/* <li>
                  <a href="#" className="icon solid style2 fa-phone">
                    <span className="label">Phone</span>
                  </a>
                </li>
                <li>
                  <a href="#" className="icon solid style2 fa-envelope">
                    <span className="label">Email</span>
                  </a>
                </li> */}
              </ul>
            </section>
            <ul className="copyright">
              <li>&copy; Mrs Piano. All rights reserved</li>
              <li>Main image &copy; Hand Painted Stuff. All rights reserved</li>
              <li>
                Design: <a href="http://html5up.net">HTML5 UP</a>
              </li>
            </ul>
          </div>
        </footer>
      </div>
    </body>
  );
}

export default App;
